/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewChecked, AfterViewInit, Component, ElementRef, forwardRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { TextareaService } from './textarea.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextareaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTextareaComponent),
      multi: true
    }
  ]
})
export class InputTextareaComponent implements AfterViewInit {
  @ViewChild('textareaElement') textareaElement: ElementRef<any>;

  @Input() inputFormGroup: FormGroup = null;
  @Input() inputFormControlName: string = null;
  @Input() placeholder: string = null;
  @Input() maxLength: number = null;
  @Input() rows: number = 3;
  @Input() maxConsecutiveNewLines: number = 4;
  @Input() label: string = null;
  @Input() labelDescription: string = null;
  @Input() labelTooltip: string = null;
  @Input() standalone: boolean = false;
  @Input() optional: boolean = false;
  @Input() optionalFeminine: boolean = false;

  @Input() isFormSubmitted: boolean = true;
  @Input() customId: string = null;
  @Input() noResize: boolean = false;

  @Input() resetListener: Subject<void> = null;

  // textarea: any = null;
  savedScrollHeight: number = null;

  // erreur de l'input
  errors: any = null;

  // id de l'input
  id: string = '';

  // La valeur interne
  value: string = '';

  // Méthode appelée lorsque la valeur change
  onChange: any = () => {};
  onTouched: any = () => {};

  get valueLength() {
    if (!this.inputFormGroup || !this.inputFormGroup.get(this.inputFormControlName)) return -1;

    return this.inputFormGroup.get(this.inputFormControlName)?.value?.length ?? 0;
  }

  constructor(
    private textareaService: TextareaService
  ) {
    this.id = this.textareaService.registerNewTextareaId();

  }

  ngAfterViewInit() {
    const fullId = 'autoresizing-' + this.id;
    const textarea = document.getElementById(fullId);

    if (this.resetListener) {
      this.resetListener.subscribe(() => {
        this.value = '';
        this.onChange(this.value);

        this.errors = null;

        if (textarea) {
          textarea.style.height = 'auto'; // Reset the textarea height
          textarea.style.height = (textarea.scrollHeight + 24) + 'px'; // Set the new height
        }
      });
    }

    if (this.noResize) return;


    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight + 24) + 'px';

    const parent = textarea.parentElement; // Get the parent element

    textarea.addEventListener('input', autoResize);

    this.inputFormGroup.get(this.inputFormControlName).valueChanges.subscribe(() => {
      textarea.style.height = 'auto'; // Reset the textarea height
      textarea.style.height = (textarea.scrollHeight + 24) + 'px'; // Set the new height
    });

    function autoResize() {
      const parentScrollTop = parent.scrollTop; // Capture parent scroll position

      this.style.height = 'auto'; // Reset the textarea height
      this.style.height = (this.scrollHeight + 24) + 'px'; // Set the new height

      parent.scrollTop = parentScrollTop; // Restore parent scroll position
    }
  }

  // Reçoit la nouvelle valeur du parent (formControl)
  writeValue(value: string): void {
    this.value = value;
  }

  // Enregistre la fonction à appeler lorsque la valeur change dans le formulaire
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Enregistre la fonction à appeler lorsque l'input est touché
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Retourne les erreurs de validation du contrôle
  validate(control: AbstractControl): ValidationErrors | null {
    // Si la valeur du contrôle est invalide, appliquez la logique d'erreur ici
    if (control.errors) {
      this.errors = control.errors;
      return control.errors;
    } else {
      this.errors = null;
    }
    return null;
  }

  // Appelée lorsque l'utilisateur tape dans l'input
  onInputChange(input: any) {
    const currentValue = input.value;

    // Limiter les sauts de ligne consécutifs
    const updatedValue = this.limitConsecutiveNewLines(currentValue, this.maxConsecutiveNewLines);

    // Mettre à jour la valeur du textarea directement si elle change
    if (updatedValue !== currentValue) {
      input.value = updatedValue;
    }

    this.value = updatedValue;
    this.onChange(this.value); // Informe Angular du changement de valeur
    this.onTouched();     // Marque le champ comme touché

    const control = this.inputFormGroup?.get(this.inputFormControlName);

    if (control) {
      this.validate(control);
    }
  }

  limitConsecutiveNewLines(value: string, maxConsecutivNewLines: number): string {
    if (!value) return value;

    if (maxConsecutivNewLines <= 0) return value;

    const regex = new RegExp(`\\n{${maxConsecutivNewLines + 1},}`, 'g');

    return value.replace(regex, '\n'.repeat(maxConsecutivNewLines));
  }
}
