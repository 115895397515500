export enum FieldTypeEnum {
    Text = 0,
    Number = 1,
    Date = 2,
    Checkbox = 3,
    Radio = 4,
    Select = 5,
    TextArea = 6,
    Phone = 7
}
