<div class="mInput_text_label-container">
    <app-input-label [label]="label" [labelDescription]="labelDescription" [labelTooltip]="labelTooltip" [errors]="errors ?? inputFormGroup?.get(inputFormControlName)?.errors ?? null" [isFormSubmitted]="isFormSubmitted" [optional]="optional" [optionalFeminine]="optionalFeminine"></app-input-label>
</div>

<!-- Textarea with form instance -->
<div *ngIf="!standalone && inputFormGroup" [formGroup]="inputFormGroup" class="mInput_textarea_container" [id]="customId">
    <textarea
        #textareaElement
        [id]="'autoresizing-' + id"
        class="input-text"
        [ngClass]="{
            'input-text-counter': maxLength > 0,
            'input-text-error': inputFormGroup.get(inputFormControlName).invalid && isFormSubmitted
        }"

        [formControlName]="inputFormControlName"
        [placeholder]="placeholder"

        (input)="onInputChange($event.target)"
    ></textarea>

    <div class="mInput_textarea_char-counter" *ngIf="maxLength > 0">
        <b [ngClass]="{'mInput_textarea_char-counter-error': valueLength > maxLength}">{{valueLength}}</b> / {{maxLength}}
    </div>
</div>

<!-- Textarea standalone -->
<div *ngIf="standalone" class="mInput_textarea_container" [id]="customId">
    <textarea
        #textareaElement
        [id]="'autoresizing-' + id"
        class="input-text"

        [rows]="rows"
        [placeholder]="placeholder"
    ></textarea>
</div>
