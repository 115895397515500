<div class="mInput_text_label-container">
    <app-input-label [label]="label" [labelBottom]="labelBottom" [labelWidth]="labelWidth" [labelDescription]="labelDescription" [labelTooltip]="labelTooltip" [errors]="inputFormGroup?.get(inputFormControlName)?.invalid && isFormSubmitted ? errors ?? inputFormGroup?.get(inputFormControlName)?.errors ?? null : null" [isFormSubmitted]="isFormSubmitted" [optional]="optional" [optionalFeminine]="optionalFeminine"></app-input-label>
</div>

<!-- Input enabled -->
<div [formGroup]="inputFormGroup" class="mInput_text_container" *ngIf="!standalone && !disabled && inputFormGroup" [style]="('width: ' + forcedWidth + ' !important')">
    <input
        [id]="customId"
        type="text"
        class="input-text"
        [ngClass]="{
            'input-text-counter': maxLength > 0,
            'input-text-error': (inputFormGroup?.get(inputFormControlName)?.invalid && isFormSubmitted) || forcedError
        }"

        [type]="type"
        [min]="min"
        [max]="max"
        [tabindex]="tabIndex"

        [formControlName]="inputFormControlName"
        [placeholder]="placeholder"
        [style]="style"

        (input)="onInputChange($event.target)"
        autocomplete="off"
    >

    <div class="mInput_text_char-counter" *ngIf="maxLength > 0">
        <b [ngClass]="{'mInput_text_char-counter-error': valueLength > maxLength}">{{valueLength}}</b> / {{maxLength}}
    </div>
</div>


<!-- Input disabled -->
<div class="mInput_text_container" *ngIf="!standalone && disabled" [style]="('width: ' + forcedWidth + ' !important')">
    <input 
        disabled
        [value]="value"
        [tabindex]="tabIndex"
        class="input-text input-disabled-text"
    >
</div>

<!-- Input standalone -->
<div class="mInput_text_container" *ngIf="standalone" [style]="('width: ' + forcedWidth + ' !important')">
    <input
        [id]="customId"
        [type]="type"
        class="input-text"
        [min]="min"
        [tabindex]="tabIndex"
        [max]="max"
        [ngClass]="{
            'input-text-counter': maxLength > 0,
        }"

        [placeholder]="placeholder"
        autocomplete="off"
    >
</div>